/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { convertDateInTimezone, getStatus, getTimeToShow, youTubeGetID, getPermissionStatus, convertMonthFromDate } from "../../../utils/methods";
import moment from "moment";
import { getInviteAmigosList, getUserListOfChallenges, getBuddiesListOfChallenges } from "../../../redux/actions";
import { connect } from "react-redux";
import { MyTaskContainerV2, ButtonWrapperNew, PrintButtonBehavior} from './styles';
import { toast } from "react-toastify";
import { isNull, isUndefined } from "lodash";
const vimeoUrl = "https://vimeo.com/api/oembed.json?url=";
import { EventDateContainerNew, EventDateContainer } from '../styles';
import ParticipantsPopUp from '../participantsInChallengePopUp';
import { Border} from '../../EventDetails/styles';
import CSVList from "../../AttendeesModal/CSVList";
import { withTranslation } from 'react-i18next';
import LazyImage from '../../common/LazyImage/LazyImage';
import { ImageUrl } from '../../../utils/constants';

let csvHeaders = [];
class MyTaskTabV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      isChallengeLocked: getStatus(props.challenge),
      activeDate: moment(moment()._d, 'MM DD YYYY').format('MM-DD-YYYY'),
      showStartChallenge: false,
      showCompletedStatus: true,
      showInviteAmigoPopup: false,
      valid: false,
      joinOrLeave: 0,
      dailyObj: 0,
      daysLeft: 0,
      currentData: false,
      showVideo: false,
      showParticipants: false,
      dynamicImageUrl: '',
      printChallengeCSV: false,
      updatedChallengeCSV: {},
    }
  }
  componentDidMount() {
    const { challenge, selectedDate, liveChallengeStatus, getBuddiesListOfChallenges } = this.props;
    this.setState({
      time: getTimeToShow(challenge, 'challenge'),
      activeDate: selectedDate
    }, () => !liveChallengeStatus && this.daysDetails(this.state.time, this.state.activeDate))

    if (challenge.introduction_video) {
      if (!isNull(challenge.introduction_video) && !isUndefined(challenge.introduction_video) && challenge.introduction_video.includes("vimeo.com")) {
        const headers = {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
          "Access-Control-Allow-Headers": "X-Requested-With, content-type, Authorization"
        };
        let url = `${vimeoUrl}${challenge.introduction_video}`;
        fetch(url, headers)
          .then((res) => res.json())
          .then((res) => {
            this.setState({ dynamicImageUrl: res.thumbnail_url })
          })
          .catch(() =>
            toast.error("Can’t access response!")
          );
      }
      if ((!isNull(challenge.introduction_video) && !isUndefined(challenge.introduction_video)) && challenge.introduction_video.includes("youtu")) {
        const getYoutudeId = youTubeGetID(challenge.introduction_video)
        let youtudeImageUrl = `https://img.youtube.com/vi/${getYoutudeId}/0.jpg`
        this.setState({ dynamicImageUrl: youtudeImageUrl })
      }
    }

    // getUserListOfChallenges(challenge.id);
    getBuddiesListOfChallenges(challenge.id, 1);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.challenge !== this.props.challenge) {
      this.setState({
        time: getTimeToShow(this.props.challenge, 'challenge'),
        activeDate: this.props.selectedDate
      }, () => !this.props.liveChallengeStatus && this.daysDetails(this.state.time, this.state.activeDate))
    }
  }
  daysDetails = (time, activeDate) => {
    const { challenge, setObjIndex, objIndex, taskUpdated, selectedDate } = this.props;
    let challengeOver = true;
    time.map((step) => {
      const values = step.split(' ');
      if (values && parseInt(values[0],10) > 0) {
        challengeOver = false;
      }
    });
    const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    const joinOrLeave = !challengeOver && (challenge.challenge_status === 'joined' ? 0 : 1);
    let today = moment()._d;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    let endDate = convertDateInTimezone(challenge.end_date)._d;
    let arr = [];
    let index = 0;
    let currentLength = moment(today, 'MM DD YYYY').diff(startDay, 'days');
    if (currentLength >= 0) {
      while (index <= (currentLength + 1)) {
        let dateObj = moment(startDay, 'MM DD YYYY').add(index, 'days').format('MM-DD-YYYY');
        arr.push(dateObj);
        index++;
      }
    }
    let selectedDay = taskUpdated ? selectedDate : activeDate;
    let objDayIndex = taskUpdated ? objIndex : arr.findIndex((date) => selectedDay == date);
    let dailyObj = (objDayIndex >= 0) ? challenge?.behavior_challenge_details?.[objDayIndex] : {};
    let daysLeft = moment(endDate, 'MM DD YYYY').diff(today, 'days');
    let currentData = selectedDay == moment(today, 'MM DD YYYY').format('MM-DD-YYYY');
    setObjIndex(objDayIndex);
    this.setState({ valid: valid, joinOrLeave: joinOrLeave, dailyObj: dailyObj, daysLeft: daysLeft, startDay: startDay, currentData: currentData });
  }

  showHideInviteAmigoPopup = () => {
    this.setState((prevState) => ({
      showInviteAmigoPopup: !prevState.showInviteAmigoPopup
    }), () => this.props.getInviteAmigosList(this.props.userId, this.props.challenge.id))
  };

  changeParticipantsModal = () => {
    this.setState((prevState) => ({
      showParticipants: !prevState.showParticipants
    }));
  }

  printAction = (bool) => {
    const { challenge, challengeAttendees } = this.props;
    let updatedChallengeCSV = challenge.challenge_type === 'behavior' &&
    challengeAttendees&&challengeAttendees.length>0&& challengeAttendees.map((behavior) => {
      let obj = {};
      obj['FIRST NAME'] = behavior['fname'];
      obj['LAST NAME'] = behavior['lname'];
      obj['EMAIL'] = behavior['mail'];
      obj['company_name'] = behavior['company_name'];
      obj['task_completion_days'] = behavior['task_completion_days'];
      return obj;
    })

    this.setState({ printChallengeCSV: bool, updatedChallengeCSV: updatedChallengeCSV })
  };

  getCSVHeaders = () => {
    const { challenge } = this.props;
    const challengeType = challenge && challenge.challenge_type;
    if (challengeType === 'behavior') {
      csvHeaders = [
        { label: "First Name", key: "FIRST NAME" },
        { label: "Last Name", key: "LAST NAME" },
        { label: "Email", key: "EMAIL" },
        { label: "Company Name", key: "company_name" },
        { label: "Task Completion Days", key: "task_completion_days" },
      ];
    }
    return csvHeaders;
  };

  render() {
    const { challenge, userPermissions, challengeAttendees, amigosList, t, role } = this.props;
    const { valid, updatedChallengeCSV, printChallengeCSV, showParticipants } = this.state;
    const fileTitle = challenge && challenge.title.replace(/ /g, "_");
    const lang = localStorage.getItem("lang");

    return (
      <MyTaskContainerV2 margin={"1"}>
        <ButtonWrapperNew>
          <div className="lastButton">
            {(challenge.is_global_challenge && role === "ADMIN" && (challenge.challenge_status == 'over' || !valid))  || ((getPermissionStatus("Download challenge CSV", userPermissions)) && (challenge.challenge_status == 'over' || !valid)) && 
              <PrintButtonBehavior onClick={() => this.printAction(true)}>
                <div><img src="/public/images/NewDashboardV2/downloadCSV.png" alt="back-arrow" /></div>
                <div >
                  <span>{t("Download Challenge CSV")}</span>
                </div>
              </PrintButtonBehavior>}
          </div>
        </ButtonWrapperNew>
        <EventDateContainer color={"#69C2FF"} cursor={"1"}>
          <LazyImage src={ImageUrl + "/images/NewDashboardV2/calendarDate.png"} alt="back-arrow"/>
          <div>
            <div>
              <div className="attendies">{t("Start Date")}
              </div>
              <div>{ (lang !== "fr") ? (convertMonthFromDate(moment(convertDateInTimezone(challenge.start_date)._d).format('MMM Do, YYYY'), this.props)) : (convertMonthFromDate(moment(convertDateInTimezone(challenge.start_date)._d).format('D MMM YYYY'), this.props))}
              </div>
            </div>
            <Border />
            <div>
              <div>{t("End Date")}</div>
              <div>{ (lang !== "fr") ? (convertMonthFromDate(moment(convertDateInTimezone(challenge.end_date)._d).format('MMM Do, YYYY'), this.props)) : (convertMonthFromDate(moment(convertDateInTimezone(challenge.end_date)._d).format('D MMM YYYY'), this.props))}</div>
            </div>
          </div>
        </EventDateContainer>
        <EventDateContainer color={"#9D87A9"} cursor={"1"}>
          <LazyImage src={ImageUrl + "/images/NewDashboardV2/EventsAttendies.png"}/>
          <div>
            <div onClick={this.changeParticipantsModal}>
              <div className="attendies">{t("Participants")}</div>
              <div>{challengeAttendees.length}</div>
            </div>
            <Border />
            <div>
              <div>{t("Buddies")}</div>
              <div>{challenge.buddies_count}</div>
            </div>
          </div>
        </EventDateContainer>
        <EventDateContainerNew>
          <div>
            <div>{t("Point Value")}</div>
            <div>+{challenge.challenge_point}{t("pts")}</div>
          </div>
          <Border />
          <div>
            <div>{t("Location")}</div>
            <div>{t(challenge.challenge_location)}</div>
          </div>
        </EventDateContainerNew>
        <ParticipantsPopUp
          showModal={showParticipants}
          onClose={this.changeParticipantsModal}
          challengeAttendees={challengeAttendees}
          amigosList={amigosList}
        />
        {printChallengeCSV && <CSVList Title={`${fileTitle}_${challenge && challenge.id}`} removeCSVData={this.printAction} headers={this.getCSVHeaders()} data={updatedChallengeCSV} />}
      </MyTaskContainerV2>
    );
  }
}
MyTaskTabV2.propTypes = {
  challenge: PropTypes.object.isRequired,
  location: PropTypes.object,
  userId: PropTypes.number.isRequired,
  profileImage: PropTypes.string.isRequired,
  history: PropTypes.object,
  user: PropTypes.object,
  updateDailyTask: PropTypes.func,
  liveChallengeStatus: PropTypes.bool,
  isDummyChallenge: PropTypes.bool,
  showHideInviteAmigoPopup: PropTypes.func,
  getInviteAmigosList: PropTypes.func,
  setObjIndex: PropTypes.func,
  objIndex: PropTypes.number,
  taskUpdated: PropTypes.number,
  selectedDate: PropTypes.string,
  inviteAmigoPopUp: PropTypes.func,
  handleChallengeID: PropTypes.func,
  challengeAttendees: PropTypes.array,
  getUserListOfChallenges: PropTypes.func,
  getBuddiesListOfChallenges: PropTypes.func,
  amigosList: PropTypes.array,
  userPermissions: PropTypes.array,
  t: PropTypes.func,
  role: PropTypes.string
};
const mapStateToProps = (state) => ({
  user: state.profileData.user,
  isDummyChallenge: state.challenges.isDummyChallenge,
  challengeAttendees: state.challenges.challengeAttendees,
  amigosList: state.challenges.amigosList,
});
const mapDispatchToProps = (dispatch) => ({
  getInviteAmigosList: (userID, challengeId) => dispatch(getInviteAmigosList(userID, challengeId)),
  getUserListOfChallenges: (challengeId) => dispatch(getUserListOfChallenges(challengeId)),
  getBuddiesListOfChallenges: (challengeId, status) => dispatch(getBuddiesListOfChallenges(challengeId, status)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyTaskTabV2));