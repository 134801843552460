import styled, { css }  from 'styled-components';
import { NextButton } from '../../RegistrationForm/styles';
import {RecipeVideos, RecipeVideoContainer } from "../../Recipes/RecipeDetails/styles";
import {VideoPlayerContainer, PlayIconVideo} from '../../ChallengeDashboardV2/ChallengeInfoPopup/styles';

const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';
const LightFont = 'Rubik-Light';
const BoldFont = 'Rubik-Bold';
const MyTaskContainer = styled.div`
  display: block;
  width: 100%;
  float: left;
  margin-top: ${({margin})=> margin ? '15px' : '0px'};
`;
const ContentWrapper = styled.div`
  width: 100%;
`;
const MyTaskVideoContainer  = styled.div`
  display: block;
  width: 100%;
  border-radius: 6px;
  padding: 16px 25px;
  float: left;
  margin-top: 20px;
  @media (max-width: 400px){
    padding: 16px 7px;
  }
`;
const TodayTaskContainer  = styled.div`
  display: block;
  background: #fff;
  width: 100%;
  border-radius: 6px;
  padding: 30px 25px 16px;
  margin-top: 20px;
  float: left;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  @media (max-width: 400px){
    padding: 30px 7px 16px 20px
  }
  > div: first-child{
    font-family: ${MediumFont};
    color: #484856;
    font-size: 16px;
    margin-bottom: 15px;
  }
  > div: last-child{
    font-family: ${LightFont};
    color: #484856;
    font-size: 16px;
  }
`;
const DidYouCompleteContainer  = styled.div`
  display: block;
  width: 100%;
  border-radius: 6px;
  float: left;
  background: #fff;
  margin-top: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  > div: first-child{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: ${LightFont};
    color: #000000;
    padding: 30px 25px 16px;
    @media (max-width: 400px){
      padding: 30px 7px 16px 20px
    }
    > div{
      font-family: ${MediumFont};
      margin-right: 10px;
    }
    > div: last-child{
      margin-left: 2px;
    }
  }
`;
const ChallengeDetailsContainer = styled.div`
  border-top: 6px solid #F6F6F8;
  width: 100%;
  padding: 20px 63px 20px 83px;
  @media (max-width: 500px){
    padding: 25px;
  }
`;
const ProfileImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;
  img {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    @media (max-width: 500px) {
      width: 40px;
      height: 40px;
    }
  }
  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
  }
`;

const MyProgressWrapper = styled.div`
  width: 100%;
  display: inline-block;
  margin-top: 20px;
`;
const DailyProgressContainer = styled.div`
  width: 50%;
  border-radius: 6px;
  float: left;
  background: #fff;
  margin: 20px 10px 10px 0px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  @media (max-width: 1260px){
   width: 100%;
  }
  @media (max-width: 1000px) and (min-width: 651px){
   width: 50%;
  }
  @media (max-width: 650px){
   width: 100%;
  }
  > div: first-child {
    width: 100%;
    font-size: 14px;
    font-family: ${BoldFont};
    color: #282C37;
    opacity: 0.7;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    padding: 15px;
    text-align: center;
    border-bottom: 2px solid #E9E9E9;
  }
  > div: nth-child(2) {
    width: 100%;
    padding: 10px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;
const Days = styled.div`
  width: calc((100% - 70px) / 7);
  font-size: 14px;
  line-height: 30px;
  color: ${({nextDays})=> nextDays ? '#6D819C' : '#FFFFFF'};
  margin: 5px 10px 5px 0;
  border-radius: 5.66px;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  text-transform: Capitalize;
  background-color: ${({done, currentDay, nextDays})=>  nextDays ? '#FFFFFF' : done ? '#32C148' : currentDay ? '#4798D9' : '#FF9D9D'};
  @media(max-width: 1260px) {
    line-height: 40px;
  }
  @media (max-width: 1000px) and (min-width: 651px){
   line-height: 30px;
  }
  @media(max-width: 650px) {
    line-height: 22px;
    font-size: 12px;
  }
`;

const DaysNew = styled.div`
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 14px;
  color: ${({nextDays, currentDay, done})=> done?"#fff":nextDays ? '#0D4270' :currentDay?"#0D4270": '#FFFFFF'};
  margin: 5px 10px 5px 0;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  text-transform: Capitalize;
  font-family: rubik;
  background: ${({done, currentDay, nextDays})=>  done ? 'linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)':currentDay? "#fff":nextDays ? '#FFFFFF'  : currentDay ? '#fff' : '#FD7175'};
  border: ${({currentDay, nextDays, done})=> done?'0px': nextDays ? '2px solid #9c9c9c' : currentDay ? '3px solid #69c2ff' : '0px'};
  margin: 0px;
  align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 20px;
    margin-top: 5px;
  @media(max-width: 1260px) {
    line-height: 40px;
  }
  @media (max-width: 1000px) and (min-width: 651px){
   line-height: 30px;
  }
  @media(max-width: 650px) {
    line-height: 22px;
    font-size: 12px;
  }
`;
const LabelsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
`;

const LabelsContainerV2 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ProgressLabels = styled.div`
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  > div: first-child{
    width: 10px;
    height: 10px;
    background: ${({bgColor}) => bgColor && bgColor};
    margin-right: 5px;
  }
  > div: last-child{
    color: #000000;
    font-size: 10px;
    font-family: ${LightFont};
    text-transform: capitalize;
  }
`;

const ProgressLabelsV2 = styled.div`
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  > div: first-child{
    width: 16px;
    height: 16px;
    background: ${({bgColor}) => bgColor && bgColor};
    margin-right: 5px;
    border-radius: 50%;
  }
  > div: last-child{
    color: #0D4270;
    font-size: 12px;
    font-family: Rubik;
    text-transform: capitalize;
    line-height: 16px;
  }
`;
const ActivityDetailsContainer = styled.div`
  width: calc((100% - 20px) / 2);
  border-radius: 6px;
  float: left;
  background: #fff;
  margin-top: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  @media (max-width: 1260px){
   width: 100%;
  }
  @media (max-width: 1000px) and (min-width: 651px){
   width: calc((100% - 20px) / 2);
  }
  @media (max-width: 650px){
   width: 100%;
  }
  > div: first-child {
    width: 100%;
    font-size: 14px;
    font-family: ${BoldFont};
    color: #282C37;
    opacity: 0.7;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    padding: 15px;
    text-align: center;
    border-bottom: 2px solid #E9E9E9;
  }
  > div: last-child{
    width: 100%;
    padding: 19px 10px;
    display: flex;
    align-items: center;
  }
 `;
const ActivityDetails = styled.div`
  width: calc(100% / 2);
  display: flex;
  align-items: center;
  @media (max-width: 1260px){
   justify-content: center;
  }
  .value{
    color: #282C37;
    font-family: ${MediumFont};
    font-size: 33px;
    margin-right: 10px;
  }
  .text{
    color: #282C37;
    font-family: ${RegularFont};
    font-size: 10px;
    text-transform: capitalize;
  }
  > img{
    width: 22px;
    height: 24px;
    margin-right: 10px;
  }
  > i: before{
    font-size: 22px;
    margin-right: 10px;
    color: #F8B739;
  }
`;

const WeekStarContainer = styled.div`
  width: calc((100% - 20px) / 2);
  border-radius: 6px;
  float: left;
  background: #fff;
  margin-top: 20px;
  padding: ${({ padding }) => padding ? '30px 10px' : '10px'};
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  @media (max-width: 1260px){
   width: 100%;
  }
  @media (max-width: 1000px) and (min-width: 651px){
   width: calc((100% - 20px) / 2);
  }
  @media (max-width: 650px){
   width: 100%;
  }
  > div: first-child{
    width: 20%;
    float: left;
    >div{
      color: #282C37;
      font-family: ${RegularFont};
      font-size: 12px;
      text-align: center;
      padding: 11px 0;
    }
  }
  > div: last-child{
    width: 80%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    > div{
     width: calc(100% / 7);
     display: flex;
     align-items: center;
     justify-content: center;
     flex-wrap: wrap;
     padding: 8px 0px;
     > svg{
       height: 22px;
       width: 22px;
     }
    }
  }
 `;
const SvgContainer = styled.svg`
  float: left;
  fill: ${({ fill }) => fill ? '#F8B739' : '#D3D9E1'};
  margin-left: 8px;
`;

const ButtonContainer = styled.div`
  float: left;
  justify-content: space-evenly;
  width: 100%;
  padding: 0px 20px;
  
  @media (max-width: 500px) {
    padding: 0;
  }
`;

const ButtonContainerNew = styled.div`
  float: left;
  justify-content: space-evenly;
  width: 100%;
  padding: 0px 20px;
  height: 0px;
  position: relative;
  
  @media (max-width: 500px) {
    padding: 0;
  }
`;

const Buttons = styled(NextButton)`
  width: 50px;
  background-color: white;
  float: right;
  padding: 10px;
  font-size: 13px;
  color: ${({Textcolor}) => Textcolor ? Textcolor : ''};
  border: none;
  box-shadow: 3px 3px 3px #bbbbbb;
  
  &:first-child {
    float: left;
    > i {
      margin-left: -5px;
    }
  }

  &: nth-child(2) {
    > i {
      margin-left: 5px;
    }
  }
  
  > i {
    margin-right: 0;
    font-size: 33px;
  }
  @media (max-width: 800px){
    padding: 0px;
    height: 50px;
    width: 50px;
  }
  ${({color}) => color && css`
    > i {
      margin-left: 0;
      margin-right: 5px;
    }
  `}
`;
const VideoPlayer = styled(VideoPlayerContainer)`
  margin-left: 0px;
  > img{
    border-radius: 10px;
  }
  > div{
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.33);
    border-radius: 6px;
  }
`;
const VideoContainer = styled(RecipeVideos)`
  width: 100%;
  margin-top: 20px;
  height: 100%;
`;
const PlayIcon = styled(PlayIconVideo)`
  width: 66px;
  height: 66px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
  vertical-align: middle;
  position: absolute;
  left: calc(50% - 33px);
  top: calc(50% - 33px);
  opacity: 1;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center; 
`;
const ViewContainer = styled.div`
  height: 350px;
  display: block;
  width: 100%;
  border-radius: 6px;
  padding: 16px 25px;
  float: left;
  margin-top: 20px;
`;
const RecipeContainer = styled(RecipeVideoContainer)`
  height: 100%;
  flex-wrap: wrap;
`;
const Title = styled.span`
  font-size: 18px;
  text-transform: capitalize;
  float: left;
  text-align: left;
  position: absolute;
  font-family: Rubik-Medium;
  top: 25px;
  left: 35px;
  color: rgb(255, 255, 255);
  width: auto !important;
  height: unset !important;
`;
const Opacity = styled.div`
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.33);
    position: absolute;
`; 

const ViewContainerNew = styled.div`
  height: 350px;
  display: block;
  width: 100%;
  border-radius: 6px;
  padding: 16px 25px;
  float: left;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
`;

const MyTaskContainerV2 = styled.div`
  display: block;
  width: 100%;
  float: left;
  margin-top: ${({margin})=> margin ? '15px' : '0px'};
  display: flex;
  flex-wrap: wrap;
`;

const ViewContainerV2 = styled.div`
  min-height: 170px;
  display: block;
  width: 100%;
  border-radius: 6px;
  float: left;
`;

const VideoContainerV2 = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #1E6481 0%, #0D425C 60.94%, #022C43 100%);
  border-radius: 6px;
  padding-bottom: 40px;
  > div: first-child{
    width: 70%;
    font-family: rubik-medium;
    color: white;
    font-size: 18px;
    lin-height: 12px;
    display: flex;
    justify-content: center;
    padding: 20px 0 40px 0px;
    padding: ${({padding})=> padding ? padding : '20px 0 40px 0px'};
    text-transform: capitalize;
    margin: auto;
    text-align: center;

  }
  > div: last-child{
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const VideoPlayerV2 = styled(VideoPlayerContainer)`
  margin-left: 0px;
  background: #0D4270;
  > img{
    border-radius: 10px;
  }
  > div{
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.33);
    border-radius: 6px;
  }
`;

const TodayTaskButton = styled.div`
min-width:250px;
height: 50px;
background: linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%);
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
border-radius: 6px;
padding-left: 10px;
padding-right: 10px;
font-family: Rubik-Medium;
font-size: 16px;
line-height: 19px;
color: #FFFFFF;
`;

const ChallengeButton = styled.div`
width:220px;
height: 30px;
background: rgba(156,156,156,0.1);
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
border-radius: 6px;
text-transform: capitalize;

font-family: Rubik-Medium;
font-size: 18px;
line-height: 18px;
color: #0D4270;
`;

const TodayTaskContainerV2  = styled.div`
  display: block;
  background: #fff;
  width: 100%;
  border-radius: 6px;
  padding: 30px 25px 16px;
  margin-bottom: 15px;
  float: left;
  @media (max-width: 400px){
    padding: 30px 7px 16px 20px
  }
  > div: first-child{
    font-family: ${MediumFont};
    color: #0D4270;
    font-size: 16px;
    line-height: 20px
    margin-bottom: 15px;
  }
  > div: last-child{
    font-family: ${LightFont};
    color: #0D4270;
    font-size: 16px;
    line-height: 20px
  }
`;

const ButtonWrapper = styled.div`
width: 100%;
display: flex;
align-items: center;
margin: auto;
justify-content: center;
.firstButton{
  display: flex;
justify-content: flex-start;
width: 50%;
}
.lastButton{
  justify-content: flex-end;
  width: 50%;
  display: flex;
}
`;

const ButtonWrapperNew = styled.div`
width: 100%;
display: flex;
align-items: center;
margin: auto;
justify-content: flex-end;
.firstButton{
  display: flex;
justify-content: flex-start;
width: 50%;
}
.lastButton{
  justify-content: flex-end;
  width: 50%;
  display: flex;
}
`;

const PrintButtonBehavior = styled.div`
width: 225px;
height: 40px;
background: #69C2FF;
display: flex;
justify-content: center;
align-items: center;
justify-content: flex-start;
border-radius: 6px;
cursor: pointer;
margin-bottom: 15px;
>div:first-child{
  display: flex;
  alig-items: center;
>img{
  width: 24px;
  height: 24px;
  margin-left: 15px;
}
}
>div:last-child{
  margin-left: 8px;
  display: flex;
  alig-items: center;
  >span{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#FFFFFF;
}
}
`;

const PrintButton = styled.div`
width: 225px;
height: 40px;
background: #69C2FF;
display: flex;
justify-content: center;
align-items: center;
justify-content: flex-start;
border-radius: 6px;
cursor: pointer;
margin-bottom: 15px;
>div:first-child{
  display: flex;
  alig-items: center;
>img{
  width: 24px;
  height: 24px;
  margin-left: 15px;
}
}
>div:last-child{
  margin-left: 8px;
  display: flex;
  alig-items: center;
  >span{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#FFFFFF;
}
}
`;

const ButtonsLeftRight = styled.div`
width: 110px;
heigt: 0px;
display: flex;
> div: first-child{
  width: 50%;
  display: flex;
  justify-content: flex-start;
  >image{
    width: 30px;
    height: 30px;
  }
}
> div: last-child{
  width: 50%;
  display: flex;
  justify-content: flex-end;
  >image{
    width: 30px;
    height: 30px;
  }
}
`;

const ButtonsNew = styled(NextButton)`
  width: 30px;
  height: 30px;
  background-color: #FD7175;
  float: right;
  padding: 10px;
  font-size: 13px;
  color: #fff;
  border: none;
  position: relative;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  padding: 5px 2px 0px 5px;
  ${({disabled}) => disabled && css`
  cursor: not-allowed !important;
  background-color: rgba(0, 47, 71, 1);
  color: rgba(255, 255, 255, 0.45);
`}


  &:first-child {
    float: left;
    > i {
      margin-left: -5px;
    }
  }

  &: nth-child(2) {
    > i {
      margin-left: 5px;
      margin:0px;
    }
  }
  
  > i {
    margin-right: 0;
    font-size: 22px;
  }
  @media (max-width: 800px){
    padding: 0px;
    height: 50px;
    width: 50px;
  }
  ${({color}) => color && css`
    > i {
      margin-left: 0;
      margin-right: 5px;
    }
  `}
`;


const DailyProgressMain = styled.div`
width: 100%;
background: white;
display: flex;
flex-wrap: wrap;
border-radius: 6px;
margin-bottom: 15px;
> div: first-child{
width: 100%;
margin-bottom: 25px;
}
> div: last-child{
  width: 100%;
  margin-bottom: 25px;
}
`;

const DailyProgressFirst = styled.div`
width: 100%;
display: flex;
> div: first-child{
width: 50%;
margin-top: 15px;
margin-left: 15px;
idsplay: flex;
justicy-content: flex-start;
>span{
  font-family: Rubik;
  font-size: 18px;
  line-height: 15px;
  color:#0D4270
}
}
> div: last-child{
  width: 50%;
  margin-top: 15px;
margin-left: 25px;
idsplay: flex;
justicy-content: flex-end;
}
`;

const DailyProgressLast = styled.div`
width: 100%;
>div{
  width: 100%;
  display: flex;
  margin-left: 25px;
  flex-wrap: wrap;
}
`;


const ToalDaysCard = styled.div`
 width: 100%;
 display: flex;
 height: 90px;
 background: white;
 border-radius: 6px;
 margin-bottom: 15px;
  > div: first-child{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    > div: first-child{
      >img{
        width: 60px;
        height: 60px;
        margin: 15px;
      }
    }
    > div: last-child{
      font-family: Rubik;
      font-size: 16px;
      line-height: 19px;
      color:#0D4270;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  > div: last-child{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    font family: 'Rubik-Medium';
    font-size: 24px;
    line-height: 28px;
    color: ${({color})=> color ? '#96C26E' : '#F6B479'};
    margin-right: 25px;
  }
`;

const ParticipantCount = styled.div`
width: 100%;
height: 375px;
background: ${({background})=> background ? background : 'linear-gradient(133.03deg, #AF87C1 25.62%, #6781C3 73.49%)'};
border-radius: 0px 0px 6px 6px;
> div: first-child{
padding-top: 92px;
width: 100%;

font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 24px;
color:#FFFFFF;
display: flex;
justify-content: center;
}
> div: last-child{
width: 100%;
font-family: 'Rubik-Medium';
font-size: 120px;
line-height: 149px;
color:#F6B479;
color: ${({color})=> color ? color : 'white'};
display: flex;
justify-content: center;
}
`;


const RightContainer = styled.div`
  width: 36.1%;
  margin-top: 40px;
  margin-top: ${({margin})=> margin ? margin : '0px'};
`;

export {MyTaskContainer, ContentWrapper, MyTaskVideoContainer, TodayTaskContainer, DidYouCompleteContainer, ChallengeDetailsContainer, ProfileImage, MyProgressWrapper, DailyProgressContainer, Days, ProgressLabels, LabelsContainer, ActivityDetailsContainer, ActivityDetails, WeekStarContainer, SvgContainer, Buttons, ButtonContainer, VideoPlayer,VideoContainer, PlayIcon,ViewContainer, RecipeContainer, Title, Opacity, ViewContainerNew, MyTaskContainerV2, ViewContainerV2, VideoContainerV2, VideoPlayerV2, TodayTaskButton, ChallengeButton, TodayTaskContainerV2, ButtonWrapper, PrintButton, ButtonsLeftRight, ButtonContainerNew, ButtonsNew, DailyProgressMain, DailyProgressLast, DailyProgressFirst, ProgressLabelsV2, LabelsContainerV2, DaysNew, ToalDaysCard, ParticipantCount, RightContainer, ButtonWrapperNew, PrintButtonBehavior }